import InformationIcon from '@/svg/InformationIcon'
import TextButton from '@/atoms/TextButton'
import { getDeadlineInfo } from '@/processes/houseDesignDeadline'

type Props = {
  deadline?: string
  passed?: boolean
  onClick: () => void
}

const Deadline = ({ deadline, passed, onClick }: Props) => {
  const { content, variant } = getDeadlineInfo(deadline, passed)
  return (
    <TextButton
      size="sm"
      onClick={onClick}
      text={content}
      iconAfter={InformationIcon}
      variant={variant}
    />
  )
}

export default Deadline
