import ArticlePage from '@/components/contentTypes/ArticlePage'
import FooterPage from '@/components/contentTypes/FooterPage'
import HouseType from '@/components/contentTypes/HouseType'
import Page from '@/components/contentTypes/Page'
import Password from '@/components/contentTypes/Password'
import HouseDesign from '@/components/contentTypes/HouseDesign'
import useMatch from '@/hooks/useMatch'
import { PageType } from '@/types/pageType'
import { NextPage } from 'next'

const ProjectPage: NextPage = () => {
  const { pageType } = useMatch()
  switch (pageType) {
    case PageType.article:
      return <ArticlePage />
    case PageType.footer:
      return <FooterPage />
    case PageType.houseType:
      return <HouseType />
    case PageType.password:
      return <Password />
    case PageType.wishList:
      return <HouseDesign />
    case PageType.page:
    default:
      return <Page />
  }
}

export default ProjectPage
