import moment from 'moment'
import { formatDateWithFullMonth } from '@/processes/dateTime'
import { TextButtonVariant } from '@/atoms/TextButton'
import { WishListSubcategory } from '@/entities/wishList'

type DeadlineInfo = { content: string; variant: TextButtonVariant }

const DEFAULT_INFO: DeadlineInfo = {
  content: `Beslisperiode nog onbekend—informeer bij woonadviseur`,
  variant: 'brand2',
}

type DeadlineState = 'valid' | 'expiring' | 'expired' | 'unknown'

export const getDeadlineState = (date?: string, passed = false): DeadlineState => {
  if (!date) {
    return 'unknown'
  }
  try {
    const deadline = moment(date)
    const now = moment()
    const daysUntilDeadline = deadline.diff(now, 'days')

    if (passed || daysUntilDeadline < 0) {
      return 'expired'
    }
    if (daysUntilDeadline <= 5) {
      return 'expiring'
    }
    return 'valid'
  } catch (e) {
    return 'unknown'
  }
}

export const isExpired = ({ deadline, deadlinePassed }: WishListSubcategory) => {
  if (deadlinePassed) {
    return true
  }
  return getDeadlineState(deadline) === 'expired'
}

export const getDeadlineInfo = (date?: string | null, passed?: boolean): DeadlineInfo => {
  if (!date) {
    return DEFAULT_INFO
  }
  const state = getDeadlineState(date, passed)
  const formattedDate = formatDateWithFullMonth(date)

  switch (state) {
    case 'unknown':
      return DEFAULT_INFO
    case 'expired':
      return { content: `Beslisperiode verlopen op ${formattedDate}`, variant: 'gray25' }
    case 'expiring':
      return { content: `Beslisperiode verloopt op ${formattedDate}`, variant: 'red' }
    case 'valid':
    default:
      return { content: `Beslisperiode tot ${formattedDate}`, variant: 'brand2' }
  }
}
